export const optionsType = [
  { value: 'partner', label: 'Partner', type: 1 },
  { value: 'groupcompany', label: 'Group Company', type: 2 },
  { value: 'client', label: 'Client', type: 3 },
];

export const PUBLIC = 'PUBLIC';
export const PRIVATE = 'PRIVATE';
export const DEFAULT = 'DEFAULT';

export const roleTypes = [
  { value: 'SuperAdmin', label: 'SuperAdmin', role: 1 },
  { value: 'Admin', label: 'Admin', role: 2 },
  { value: 'User', label: 'User', role: 3 },
];

export const PERMISSION_DEFAULT = {
  displayName: 'All Permissions',
  name: 'PERMISSIONS.ALL',
  description: 'Default Parent Permission for all permissions',
  children: [],
};
export const recursiveKeys = (obj, keyname) => {
  let data = [obj[keyname]];
  // eslint-disable-next-line no-restricted-syntax
  for (const child of obj.children) {
    data = data.concat(recursiveKeys(child, keyname));
  }
  return data;
};

export const tagColors = [
  '#ff6900',
  '#fcb900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
  '#9900EF',
  '#F44336',
  '#E91E63',
  '#009688',
  '#CDDC39',
  '#607D8B',
];

// Group filterTags by categoryId
const groupTagsByCategory = (filterTags) => {
  return filterTags.reduce((acc, tag) => {
    if (!acc[tag.categoryId]) {
      acc[tag.categoryId] = [];
    }
    acc[tag.categoryId].push(tag.id);
    return acc;
  }, {});
};

// Check if resource has all required tag IDs
const hasSomeTags = (resourceTags, requiredTagIds) => {
  const tagIds = resourceTags.map((tag) => tag.id);
  return requiredTagIds.some((id) => tagIds.includes(id));
};

export const filterResourcesByTags = (list, selectedTags) => {
  if (selectedTags.length === 0) return list;

  const groupedTags = groupTagsByCategory(selectedTags);
  let filteredResources = [...list];
  for (const categoryId in groupedTags) { // eslint-disable-line
    const requiredTagIds = groupedTags[categoryId];
    filteredResources = filteredResources.filter((resource) => hasSomeTags(resource.tags, requiredTagIds));
  }

  return filteredResources;
};

export function getDeviceName() {
  const userAgent = navigator.userAgent.toLowerCase();

  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  if (/ipad|tablet/i.test(userAgent)) {
    return 'Tablet';
  }
  if (/iphone|ipod/i.test(userAgent)) {
    return 'iOS';
  }
  if (/windows/i.test(userAgent) && /touch/i.test(userAgent)) {
    return 'Windows Tablet';
  }
  if (/windows/i.test(userAgent)) {
    return 'Windows PC';
  }
  if (/macintosh/i.test(userAgent)) {
    return 'Mac Laptop/Desktop';
  }
  if (/linux/i.test(userAgent)) {
    return 'Linux';
  }
  return 'UNKNOWN';
}

export function getDeviceType() {
  const userAgent = navigator.userAgent.toLowerCase();

  if (/android/i.test(userAgent)) {
    return 'Handheld';
  }
  if (/ipad|tablet/i.test(userAgent)) {
    return 'Handheld';
  }
  if (/iphone|ipod/i.test(userAgent)) {
    return 'Handheld';
  }
  if (/windows/i.test(userAgent) && /touch/i.test(userAgent)) {
    return 'Desktop';
  }
  if (/windows/i.test(userAgent)) {
    return 'Desktop';
  }
  if (/macintosh/i.test(userAgent)) {
    return 'Desktop';
  }
  if (/linux/i.test(userAgent)) {
    return 'Desktop';
  }
  return 'UNKNOWN';
}
