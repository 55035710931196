import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { useSelector } from 'react-redux';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import {useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import TeamGroupDetail from "./TeamGroupDetail";
import CreateGroup from './CreateGroup';
import GroupsTab from './Groups';
import UsersTab from './UserCard';
import CreateUsers from './CreateUsers';
import Filter from '../filter/Filter';
import SearchAll from '../SearchAll';
import { UserCSVUpload, GroupCSVUpload } from './CSVUpload';
import UserSampleCSV from '../../assets/UserSampleCSV.csv';
import AssignedResources from './AssignedResources/AssignedResources';
import SettingTeamManagement from './SettingTeamManagement';
import TeamManagementLoadComp from '../LoadingComponents/TeamManagementLoadComp';

const TeamManagement = () => {
  const navigate = useNavigate();
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const permissions = userData.user.permissions;
  const [search, setSearch] = useState('');
  const [groupDetail, setGroupDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [createUser, setCreateUser] = useState(false);
  const [show, setShow] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [groupCsvUpload, setGroupCsvUpload] = useState(false);
  const [userCsvUpload, setUserCsvUpload] = useState(false);
  const [activeTab, setActiveTab] = useState('groups');
  const [usersList, setUsersList] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const params = useParams();
  const UUID = params?.uuid;

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const openCreateUserModal = () => {
    setShowUserModal(true);
  };
  const closeCreateUserModal = () => {
    setShowUserModal(false);
  };

  const openGroupCsvModal = () => {
    setGroupCsvUpload(true);
  };
  const openUserCsvModal = () => {
    setUserCsvUpload(true);
  };

  const closeUserCsvUploadModal = (status) => {
    if (status) {
      fetchGroupDetail();
      fetchUsersList();
    }
    setUserCsvUpload(false);
  };

  const closeGroupCsvUploadModal = (status) => {
    if (status) {
      fetchGroupDetail();
      fetchGroupsList();
    }
    setGroupCsvUpload(false);
  };

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  const fetchGroupDetail = async () => {
    setLoading(true)
    try {
      const resData = await axios.get(`${configData.config.UNO_URL}/api/user/group/details/${UUID}`, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      setGroupDetail({ ...resData?.data?.data });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsersList = async () => {
    try {
      const resData = await axios.get(`${configData.config.UNO_URL}/api/user/list/${UUID}`, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      setUsersList([...resData?.data?.data]);
    } catch (error) {

      console.log(error);
    }
  };

  const fetchGroupsList = async () => {
    try {
      const resData = await axios.get(`${configData.config.UNO_URL}/api/user/group/list/${UUID}`, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      setGroupsList([...resData?.data?.data]);
    } catch (error) {

      console.log(error);
    }
  };

  useEffect(() => {
    if (UUID.length) {
      setLoading(true);
      setSearch("");
      setCreateUser(false);
      fetchGroupDetail();
      fetchUsersList();
      fetchGroupsList();
      setActiveTab("groups");
    }
  }, [UUID]);

  const RenderActionsButtons = () => {
    switch (activeTab) {
      case 'groups':
        return !permissions.includes("TEAM.GROUP.CREATE") ? null : <>
          <div>
            <button type='button' onClick={openGroupCsvModal} className="uloadoanload">
              <FileUploadOutlinedIcon />
              <span>Group CSV</span>
            </button>
          </div>
          <button
            type='button'
            className="createbtn"
            onClick={handleShow}
          >
            Create Group
          </button>
        </>;
      case 'users':
        return !permissions.includes("TEAM.USERS.CREATE") ? null : <>
          <div>
            <button type='button' onClick={openUserCsvModal} className="uloadoanload">
              <FileUploadOutlinedIcon />
              <span>User CSV</span>
            </button>
          </div>
          <button
            type='button'
            className="createbtn"
            onClick={openCreateUserModal}
          >
            Create User
          </button>
        </>;
      case 'resources':
        return !permissions.includes("RESOURCE.ASSIGNMENT.ASSIGN") ? null : <>
          <button
            type='button'
            onClick={() => navigate(`/dashboard/team-management/assign/resources/group/${UUID}`)}
            className="createbtn"
          >
            Assign Resources
            
          </button>
        </>;
      default:
        return null;
    }
  }

  if (loading) {
    return <TeamManagementLoadComp/>;
  }

  return (
    <>
      <section className="">
        <div className="row align-items-center title-row" >
          <div className="col pagetitletext">
            {userData?.user?.group?.uuid !== UUID ? (
              <span onClick={() => navigate(-1)}>
                {' '}
                <button type='button' className="back">
                  {' '}
                  <KeyboardBackspaceOutlinedIcon />{' '}
                </button>{' '}
              </span>
            ) : null}
            <span>Team Management</span>
          </div>

          <div className="col text-end">
            <div className="csvuploaddownload">
              <div className="csvuploaddownload">

                <RenderActionsButtons />

              </div>
            </div>
          </div>
        </div>

        <TeamGroupDetail type="group" detail={groupDetail} refresh={fetchGroupDetail} permissions={permissions} />

        <div className="pt-3">
          <Tab.Container defaultActiveKey="groups" onSelect={handleTabChange}>
            <div className="row">
              <div className="col-md-8">
                <Nav className="tabsproduct" variant="pills">
                  {permissions.includes("TEAM.GROUP.VIEW") ?
                    <Nav.Item>
                      <Nav.Link eventKey="groups">Groups
                      { !loading &&  groupsList.length > 0 &&  <span> ({groupsList.length}) </span> } 
                      </Nav.Link>
                    </Nav.Item>
                    : null}
                  {permissions.includes("TEAM.USERS.VIEW") ?
                    <Nav.Item>
                      <Nav.Link eventKey="users">Users
                      { !loading &&  usersList.length > 0 && <span> ({usersList.length}) </span> }
                      </Nav.Link>
                    </Nav.Item>
                    : null}
                  {permissions.includes("RESOURCE.ASSIGNMENT.VIEW") ?
                    <Nav.Item>
                      <Nav.Link eventKey="resources">Assigned Resources</Nav.Link>
                    </Nav.Item>
                    : null}
                  {permissions.includes("TEAM.GROUP.SETTINGS") ?
                    <Nav.Item>
                      <Nav.Link eventKey="settings">Settings</Nav.Link>
                    </Nav.Item>
                    : null}
                </Nav>
              </div>

              <div className="col-md-4">
                <div className="d-flex justify-content-end">
                  {activeTab !== 'resources' && activeTab !== 'settings' && (
                    <div className="ms-2">
                      <SearchAll placeholder={activeTab === 'groups' ? 'Search Groups...' : 'Search Users...'} value={search} setSearch={setSearch} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Tab.Content className="mt-0">
              {
                permissions.includes("TEAM.GROUP.VIEW") ? <Tab.Pane eventKey="groups">
                  <GroupsTab search={search} setSearch={setSearch} groupsList={groupsList} detail={groupDetail} refresh={fetchGroupsList} permissions={permissions} />
                </Tab.Pane> : null
              }
              {
                permissions.includes("TEAM.USERS.VIEW") ?
                  <Tab.Pane eventKey="users">
                    <UsersTab refresh={fetchUsersList} setSearch={setSearch} usersList={usersList} search={search} permissions={permissions} />
                  </Tab.Pane>
                  : null
              }
              {
                permissions.includes("RESOURCE.ASSIGNMENT.VIEW") ?
                  <Tab.Pane eventKey="resources">
                    <AssignedResources type={"group"} permissions={permissions} />
                  </Tab.Pane> : null
              }
              {permissions.includes("TEAM.GROUP.SETTINGS") ?
                <Tab.Pane eventKey="settings">
                  <SettingTeamManagement id={UUID} type={'group'} permissions={permissions} />
                </Tab.Pane> : null}
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
      <CreateGroup show={show} handleClose={handleClose} refresh={fetchGroupsList} />
      <CreateUsers showUser={showUserModal} CreateUserModalClose={closeCreateUserModal} refresh={fetchUsersList} />
      {activeTab === 'groups' ? (
        <GroupCSVUpload showGroupCSV={groupCsvUpload} csvGroupModalClose={closeGroupCsvUploadModal} />
      ) : (
        <UserCSVUpload showUserCSV={userCsvUpload} csvUserModalClose={closeUserCsvUploadModal} />
      )}
    </>
  );
};

export default TeamManagement;
