import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { styled } from '@mui/material/styles';
import { TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { CloseIcon, DeleteIcon } from '../../SvgIconsData';
import signature from '../../assets/img/signature.png';
import DropZone from '../dropzone/DropZone';
import { optionsType, roleTypes } from "../../utils/constant";
import { createUserValidationSchema } from './validation';
import { Thumbnail } from '../thumbnail';

const CreateUsers = ({ showUser, CreateUserModalClose, refresh }) => {
    const dispatch = useDispatch();
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [showPassword, setShowPassword] = useState(false);
    const [formLoading, setFormLoading] = useState(false)
    const [error, setError] = useState(null)
    const params = useParams();
    const UUID = params?.uuid;

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            designation: "",
            avatar: "",
            uid: "",
            password: "",
            role: null,
            loginType: "1"
        },

        validationSchema: createUserValidationSchema,

        onSubmit: async (values) => {

            const data = {
                name: values.name.trim() || null,
                email: values.loginType === "1" ? values.email.trim() || null : null,
                uid: values.loginType === "2" ? values.uid.trim() || null : null,
                password: values.loginType === "2" ? values.password.trim() || null : null,
                avatar: values.avatar.trim() || null,
                designation: values.designation.trim() || null,
                phone: values.phone.trim() || null,
                role : values.role.value
            }

            try {
                setFormLoading(true)
                const resData = await axios.post(`${configData.config.UNO_URL}/api/user/create/${UUID}`, data, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                        "Content-Type ": "application/json"
                    }
                });

                if (resData?.data?.data?.name) {
                    refresh();
                    setTimeout(() => { handleModalClose() }, 500);
                }

            } catch (error) {
                console.error(error);
                setError(error?.response?.data?.message)
                setTimeout(()=> setError("") , 50000)
            }finally{
                setFormLoading(false);
            }


        }
    });

    useEffect(() => {
        if (roleTypes.length) formik.setFieldValue('role', roleTypes[2].role);
    }, [optionsType]);


    const handleFileUpload = async (data) => {
        formik.setFieldValue('avatar', data?.file)
    };

    const handleModalClose = () => {
        formik.resetForm();
        CreateUserModalClose();
        setFormLoading(false);
    }


    const handleChangeField = (e) => {
        if (e.target.name === "email" || e.target.name === "uid" || e.target.name === "password") {
            const value = e.target.value.replace(/\s/g, '');
            formik.setFieldValue(e.target.name, value)
        } else if (e.target.name === "phone") {
            const numericValue = e.target.value.replace(/\D/g, '');
            formik.setFieldValue("phone", numericValue);
        } else {
            formik.setFieldValue(e.target.name, e.target.value.replace(/\s+/g, ' '))
        }
    }

    const options = configData.roles.map((e) => ({value : e.id , label : e.name}))
    useEffect(() => {
        const defaultrole = configData.roles.filter((e) => e.type === 'DEFAULT').map((e) => ({ value: e.id, label: e.name }));
        formik.setFieldValue('role' , defaultrole[0]);
    }, [showUser])

    function debounce(func, wait) {
        let timeout;
        return function(...args) {
          const context = this;
          clearTimeout(timeout);
          timeout = setTimeout(() => func.apply(context, args), wait);
        };
      }


    const handleFormSubmit = debounce(formik.handleSubmit, 700);

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        marginRight: 10,
        boxShadow:
          theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
          theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
        },
        'input:hover ~ &': {
          backgroundColor: theme.palette.mode === 'dark' ? '#ca373c' : '#ca373c',
        },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
      }));
      
      const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#ca373c',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&::before': {
          display: 'block',
          width: 16,
          height: 16,
          backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
          content: '""',
        },
        'input:hover ~ &': {
          backgroundColor: '#ca373c',
        },
      });


    function BpRadio(props) {
        return (
          <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
          />
        );
      }

    return (
        <>
            <Modal  size='md' centered show={showUser} onHide={handleModalClose}>
                    <Modal.Header className='modalheader text-center'>
                        Create User
                        <span onClick={handleModalClose}>
                            <CloseIcon/>
                        </span>

                    </Modal.Header>
                <Modal.Body >

                    
                        <div className="modalcont">
                            <div className="row">
                                <div className="col d-flex justify-content-center">
                                    <div className='profile-Logo'>
                                        {formik.values.avatar?.length > 0 ?
                                            <div className='groupEditLogo position-relative '>
                                                <Thumbnail path={formik.values.avatar || ""} name={""} />
                                                {/* <p className='mt-2 text-center'>Avatar</p> */}
                                                <span className='closeicon position-absolute' onClick={() => formik.setFieldValue('avatar', null)} >
                                                    {/* <CloseIcon /> */}
                                                    <DeleteIcon/>
                                                </span>
                                            </div>
                                            :
                                            <DropZone text="Avatar" name="avatar" img={signature} onFileUpload={handleFileUpload} />
                                        }
                                        <p>Profile</p>
                                    </div>
                                </div>
                            </div>
                            <div className='createform'>
                                <div className="form-group">
                                    <p>Name  <span> * </span></p>
                                    <TextField
                                        name="name"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Enter Name"
                                        size="small"
                                        value={formik.values.name}
                                        onChange={handleChangeField}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </div>

                                <div className="form-group with-select">
                                <p>User Role  <span> * </span></p>
                                            <Select
                                            className="single-select"
                                            classNamePrefix="react-select"
                                            menuPlacement="auto"
                                            maxMenuHeight={200}
                                            menuPosition={'fixed'}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 5,
                                                colors: {
                                                ...theme.colors,
                                                primary50 : '#ca373c50',
                                                primary25 : '#ca373c50',
                                                primary: '#ca373c70',
                                                danger :'#000000',
                                                neutral30: '#00000050'
                                                },
                                            })}
                                            styles={{
                                                control: (styles) => ((formik.touched.role && Boolean(formik.errors.role)) ? {
                                                    ...styles,
                                                    borderColor: 'red'
                                                } : { ...styles })
                                                
                                            }} options={options} placeholder="Select Role" value={formik.values.role} onChange={(e) => formik.setFieldValue('role', e)} />
                                            {formik.touched.role ? <p className='error' style={{ fontSize: "0.75rem" }} >{formik.errors.role}</p> : null}
                                </div>

                                <FormControl className='select-option-radio' component="fieldset">
                                    <p className='mb-2'>Login Type :</p>
                                    <RadioGroup
                                        aria-label="options"
                                        name="options"
                                        value={formik.values.loginType}
                                        onChange={(e) => formik.setFieldValue("loginType", e.target.value)}
                                    >
                                        <div className='radio-options ms-3'>
                                            <FormControlLabel value="1" control={<BpRadio />} label="Email" />
                                            <FormControlLabel value="2" control={<BpRadio />} label="UID / PASSWORD" />
                                        </div>
                                    </RadioGroup>
                                </FormControl>


                                {formik.values.loginType === "1" ?
                                    <div className="form-group mt-2">
                                        
                                        <p>Email  <span> * </span></p>
                                        <TextField
                                            name="email"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Enter Email"
                                            size="small"
                                            value={formik.values.email}
                                            onChange={handleChangeField}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </div>
                                    :
                                    null
                                }

                                {formik.values.loginType === "2" ?
                                    <>
                                        <div className="form-group mt-2">
                                            
                                            <p>UID  <span> * </span></p>
                                            <TextField
                                                name="uid"
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Enter uid*"
                                                size="small"
                                                value={formik.values.uid}
                                                onChange={handleChangeField}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.uid && Boolean(formik.errors.uid)}
                                                helperText={formik.touched.uid && formik.errors.uid}
                                            />
                                        </div>

                                        <div className="form-group position-relative">
                                          
                                            <p>Password  <span> * </span></p>
                                            <TextField
                                                name="password"
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Enter password*"
                                                size="small"
                                                type={showPassword ? 'text' : 'password'}
                                                value={formik.values.password}
                                                onChange={handleChangeField}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.password && Boolean(formik.errors.password)}
                                                helperText={formik.touched.password && formik.errors.password}
                                            />
                                            <span className='vieweye' onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                                            </span>
                                        </div>

                                    </>
                                    :
                                    null
                                }

                                {error && <p className='errormessage' >{error}</p>}

                                <div className="form-group">
                                   
                                    <p>Phone </p>
                                    <TextField
                                        name="phone"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Enter Phone"
                                        size="small"
                                        type='tel'
                                        inputProps={{ maxLength: 15 }}
                                        value={formik.values.phone}
                                        onChange={handleChangeField}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                                        helperText={formik.touched.phone && formik.errors.phone}
                                    />
                                </div>

                                <div className="form-group">
                                    <p>Designation</p>
                                    <TextField
                                        name="designation"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Enter Designation"
                                        size="small"
                                        value={formik.values.designation}
                                        onChange={handleChangeField}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.designation && Boolean(formik.errors.designation)}
                                        helperText={formik.touched.designation && formik.errors.designation}
                                    />
                                </div>

                                {/* <div className="form-group">
                                    <span>Role</span>
                                    <Select
                                        options={roleTypes}
                                        name="role"
                                        onChange={(selectedOption) => formik.setFieldValue('role', selectedOption.role)}
                                        value={roleTypes.find(option => option.role === formik.values.role)}
                                        getOptionLabel={(option) => option.label}
                                    />
                                </div> */}
                            </div>
                            <div className='modal-footer-btn mt-4 mb-1'>
                            <button className='createbtn cancelbtn' onClick={handleModalClose}>
                                Cancel
                            </button>
                            { !formLoading ? 
                            <button className='createbtn ' onClick={handleFormSubmit}>
                                Create
                            </button>
                            :
                            <button className="loading save">
                                <svg viewBox="25 25 50 50">
                                    <circle r="20" cy="50" cx="50">{""}</circle>
                                </svg>
                            </button>
                            }       
                            </div>
                        </div>
                    
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateUsers
