import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import jwtEncode from 'jwt-encode';
import jwtDecode from 'jwt-decode';
import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';
import { groupTypes, roles, saveConfig } from './store/slice/configSlice';
import { removeUserData, saveUserData, saveUserPlan } from './store/slice/userSlice';
import { myApplicationList, myTagsList } from './store/slice/myAppSlice';
import FullScreenLoader from './components/loaders/FullScreenLoader';
import { UNO_URL, applicationId } from './env';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css'
import './assets/css/style.css';
import './assets/css/subsstyle.css';
import './assets/css/responsive.css';
import { moduleFilter } from './store/slice/moduleFilter';

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);

  // const [welcomeLoaded, setWelcomeLoaded] = useState(true);

  const getConfigData = async () => {
    try {

      // const visitedBefore = localStorage.getItem('visitedBefore');
      // if (visitedBefore) setWelcomeLoaded(true);

      // else localStorage.setItem('visitedBefore', '1');

      const { data } = await axios.get(`${UNO_URL}/api/application/configuration/${applicationId}`);     
      const config = { ...data.data.data };
      // config.UNO_URL = "http://localhost:3003";
      dispatch(saveConfig({ data: config }));
      
      getUserDetail(config);
      
    } catch (error) {
       console.log(error);
    }
  };


  useEffect(() => {
    getConfigData();
  }, [dispatch]);


  const getUserDetail = async (config) => {
    let referrerUrl = null;
    let redirectUri = null;
    const userToken = localStorage.getItem('token');
    const currentURL = window.location.href;
    const urlObject = new URL(currentURL);
    const referrerUrlParam = urlObject.searchParams.get('referrer');
    const logOut = urlObject.searchParams.get('logOut');

    if (logOut === 'true') {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(removeUserData());
    }

    if (referrerUrlParam) {
      const decoded = jwtDecode(referrerUrlParam);
      referrerUrl = decoded.origin;
      redirectUri = decoded.redirectUri;
    }
 
   try {
      if (userToken) {

        const { data } = await axios.get(`${config.UNO_URL}/api/user/me`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        const plansFind = await axios.get(`${config.UNO_URL}/api/user/plan/subscription/${applicationId}`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          } });
        
        const userAssignedPlan={...plansFind.data.data};
        
        localStorage.setItem('token', data.data.token);
        dispatch(saveUserPlan(userAssignedPlan));
        dispatch(saveUserData({
          token: data.data.token,
          user: data.data.user,
        }));
        
        if (referrerUrl) {
          const payload = { token: data.data.token, redirectUri };
          const token = jwtEncode(payload, '');
          window.location.href = `${referrerUrl}?referrer=${token}`;
          return;
        }

        getMyApps(config, {
          token: data.data.token,
          user: data.data.user,
        });
        getAllTags(config, {
          token: data.data.token,
          user: data.data.user,
        });
        getModuleFilters(config, {
          token: data.data.token,
          user: data.data.user,
        });
        getGroupType(config, {
          token: data.data.token,
          user: data.data.user,
        });

      }
      
    
      setLoading(false); 

    } catch (error) {
      console.log(error);
      localStorage.removeItem('token');
    }finally{
      if (referrerUrl) {
        sessionStorage.setItem('referrer', referrerUrl);
        sessionStorage.setItem('redirectUri', redirectUri);
      }
    }
    setLoading(false)
  };

  const getMyApps = async (config, userInfo) => {
    try {
      const myApp = await axios.get(`${config.UNO_URL}/api/application/mine`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      const appList = myApp?.data?.data || [];
      dispatch(myApplicationList(appList));
    } catch (error) {
      console.error(error);
    }
  };

  const getAllTags = async (config, userInfo) => {
    try {
        const getAppList = await axios.get(`${config.UNO_URL}/api/tag/list/${config.applicationCategoryId}`,  {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      const tagsList = getAppList.data.data.tags || [];
      dispatch(myTagsList(tagsList));
    } catch (error) {

      console.error(error);
    }
  };
  
  const getModuleFilters = async (config, userInfo) => {
    try {
      const modulefilters = await axios.get(
        `${config.UNO_URL}/api/tag/list/${config.moduleFilterId}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      const moduleFilterList = modulefilters.data || [];


      dispatch(moduleFilter(moduleFilterList));
    } catch (error) {

      console.error(error);
    }
  };

  const getGroupType = async (config, userInfo) => {
    try {
      const { data } = await axios.get(`${config.UNO_URL}/api/role/grouptype/list`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`
        }
      });
      dispatch(groupTypes(data.data.groupTypes));
      dispatch(roles(data.data.roles));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <FullScreenLoader loading={loading} />
      ) : (
        // !welcomeLoaded ?
        //   <>
        //     <div className='instrovid'>
        //       <video width={"100vw"} height={"100vh"} autoPlay muted onEnded={() => setWelcomeLoaded(true)} >
        //         <source src="https://simulanislegacy.blob.core.windows.net/sso/Uno%20Reveal%20(2).mp4" type="video/mp4" />
        //         Your browser does not support HTML video.
        //       </video>
        //     </div>
        //   </>
        //   : 
          <>
           
              <HelmetProvider>
                <BrowserRouter>
                  <ThemeProvider>
                    <ScrollToTop />
                    <Router />
                  </ThemeProvider>
                </BrowserRouter>
              </HelmetProvider>

          </>
      )}
    </>
  );
}
