import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from "react-redux";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from "axios"
import { Button } from '@mui/material';
import Select from 'react-select'
import { CloseIcon, DeleteIcon } from '../../SvgIconsData';
import { optionsType, roleTypes } from "../../utils/constant";
import { editGroupValidationSchema } from './validation';
import DropZone from '../dropzone/DropZone';
import sicon from '../../assets/img/sicon.png';
import { Thumbnail } from '../thumbnail';

const EditCreateGroup = ({ detail, showEditGroup, closeEditGroupModal, refresh }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState("");
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [formLoading, setFormLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            groupname: "",
            identifier: "",
            logo: "",
            groupType : null,
            active:true
        },
        validationSchema: editGroupValidationSchema,
        onSubmit: async (values) => {
            setError(null);
            setSuccess(null);
            const data = {
                name: values.groupname,
                identifier: values.identifier,
                avatar: values.logo.trim() || null,
                groupType : values.groupType.value,
                active :values.active
            }

            try {
                setFormLoading(true)
                const resData = await axios.post(`${configData.config.UNO_URL}/api/user/group/edit/${detail.uuid}`, data, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                        "Content-Type ": "application/json"
                    }
                });


                refresh();
                closeEditGroupModal();

            } catch (error) {
                console.error(error);
                setError(error?.response?.data?.message)
            }finally{
                setFormLoading(false)
            }
        }
    });

    useEffect(() => {
        if (optionsType.length) formik.setFieldValue('type', optionsType[0].type);
        if (roleTypes.length) formik.setFieldValue('role', roleTypes[0].role);

    }, [optionsType])

    const handleFileUpload = async (data) => {
        try {
            if (data?.name === "logo") { formik.setFieldValue('logo', data?.file) }
            // closeEditGroupModal()
        } catch (error) {
            console.log(error);
        }
    };
    const options = configData.groupTypes.map((e) => ({value : e.id , label : e.name}))
    useEffect(() => {
        formik.setFieldValue('groupname', detail.name);
        formik.setFieldValue('identifier', detail.identifier);
        formik.setFieldValue('logo', detail.avatar || "");
        formik.setFieldValue('active', detail.active);
        const groupTypeDetails = options.filter(e => e.value === detail.typeId)[0];
        if (groupTypeDetails) formik.setFieldValue( 'groupType',groupTypeDetails)
    }, [showEditGroup])

    const handleStatusChange = async(e) =>{
        formik.setFieldValue(e.target.name, !formik.values.active)
    }

    const handleChangeField = (e) => {
        formik.setFieldValue(e.target.name, e.target.value.replace(/\s+/g, ' '))
    }

    function debounce(func, wait) {
        let timeout;
        return function(...args) {
          const context = this;
          clearTimeout(timeout);
          timeout = setTimeout(() => func.apply(context, args), wait);
        };
      }

    const handleFormSubmit = debounce(() => formik.handleSubmit, 700);

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 38,
        height: 22,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 18,
          height: 18,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));
    return (
        <>
            <Modal size="md" centered show={showEditGroup} onHide={closeEditGroupModal}>
                <Modal.Header className='modalheader text-center'>
                        Edit Group
                        <span onClick={closeEditGroupModal}>
                            <CloseIcon/>
                        </span>
                    </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modalcont mt-2">
                            <div className="row">
                                <div className="col d-flex justify-content-center">
                                    <div className='profile-Logo'>
                                        {formik.values.logo?.length > 0 ?
                                            <div className='groupEditLogo position-relative '>
                                                <Thumbnail path={formik.values.logo || ""} name={""} />
                                                <span className='closeicon position-absolute ' onClick={() => formik.setFieldValue('logo', "")} >
                                                    <DeleteIcon />
                                                </span>
                                            </div>
                                            :
                                            <DropZone text="Logo" name="logo" img={sicon} onFileUpload={handleFileUpload} />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='createform'>
                                <div className="form-group">
                                    <p> Group Name  <span> * </span></p>
                                    <TextField
                                        name="groupname"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Enter Group Name"
                                        size="small"
                                        value={formik.values.groupname}
                                        onChange={handleChangeField}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.groupname && Boolean(formik.errors.groupname)}
                                        helperText={formik.touched.groupname && formik.errors.groupname}
                                    />
                                </div>
                                {error && <p className='errormessage' >{error}</p>}
                                <div className="form-group">
                                    <p> Identifier </p>
                                    <TextField
                                        name="identifier"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Enter Identifier"
                                        size="small"
                                        value={formik.values.identifier}
                                        onChange={handleChangeField}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.identifier && Boolean(formik.errors.identifier)}
                                        helperText={formik.touched.identifier && formik.errors.identifier}
                                    />
                                </div>
                                <div className="form-group with-select">
                                    <p>Group Type</p>
                                    <Select 
                                    className="single-select"
                                    classNamePrefix="react-select"
                                    menuPlacement="auto"
                                    maxMenuHeight={200}
                                    menuPosition={'fixed'}
                                    options={options} 
                                    value={formik.values.groupType} 
                                    onChange={(e)=>formik.setFieldValue( 'groupType',e)} 
                                    placeholder="Select Group Type" 
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        colors: {
                                        ...theme.colors,
                                        primary50 : '#ca373c50',
                                        primary25 : '#ca373c50',
                                        primary: '#ca373c70',
                                        danger :'#000000',
                                        neutral30: '#00000050'
                                        },
                                    })}
                                    styles={{
                                        control: (styles) => ((formik.touched.role && Boolean(formik.errors.role)) ? {
                                            ...styles,
                                            borderColor: 'red'
                                        } : { ...styles })
                                    }}
                                    />
                                    {formik.touched.groupType ?  <p className='text-danger ps-3 mt-1' style={{fontSize : "0.75rem"}} >{formik.errors.groupType }</p> : null}
                                </div>
                                <div className="form-group selectall-option">
                                    <p>Status</p>
                                    <div className='d-flex align-items-center justify-content-start'>
                                    <FormControlLabel
                                        className='me-0'
                                        name="active"
                                        control={<IOSSwitch sx={{ m: 1 }} checked={formik.values.active} onChange={handleStatusChange}/>}
                                    />
                                    <span className={formik.values.active ? "active-label" : "inactive-label"}>{formik.values.active ? "Active" : "Inactive"}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer-btn mt-3 mb-1'>
                            <button className='createbtn cancelbtn' onClick={closeEditGroupModal}>
                                Cancel
                            </button>
                            { !formLoading ? 
                            <button className='createbtn ' onClick={handleFormSubmit}>
                                Update
                            </button>
                            :
                            <button className="loading save">
                                <svg viewBox="25 25 50 50">
                                    <circle r="20" cy="50" cx="50">{""}</circle>
                                </svg>
                            </button>
                            }       
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditCreateGroup
